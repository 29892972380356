import UserBaseService from '../base/UserBaseService';

export class SigninApi extends UserBaseService {
    constructor() {
        super("user");
    }

    /**
   * 根据标识获取用户实体
   */
    public getUserInfoById(params: any): Promise<any> {
        return this.request('getUserInfoById', this.rest.METHOD_GET, params);
    }
    /**
   * 获取签到二维码
   */
    public generateQRCode(params: any): Promise<any> {
        return this.request('generateQRCode', this.rest.METHOD_GET, params);
    }

}