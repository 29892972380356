import { SigninApi } from '@/api/attendance/signin/SigninApi';
import { RecordApi } from '@/api/attendance/record/RecordApi';

export default class SigninService {

    private api: any;
    private recordApi: any;

    constructor() {
        this.api = new SigninApi();
        this.recordApi = new RecordApi();
    }


    /**
     * 根据标识获取用户实体
     * @param userId 
     * @param roleType 
     * @returns 
     */
    public getUserInfoById(userId: string, roleType: string): Promise<any> {
        const params = {
            userId: userId,
            roleType: roleType
        };
        return new Promise((resolve, reject) => {
            this.api.getUserInfoById(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取二维码
     * @param path 
     * @returns 
     */
    public generateQRCode(path: string): Promise<any> {
        const params = {
            text: path,
            logoName: ""
        };
        return new Promise((resolve, reject) => {
            this.api.generateQRCode(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取考勤地点
     * @param userId 
     * @returns 
     */
    public getSignPlace(userId: string): Promise<any> {
        const params = {
            userId: userId,
        };
        return new Promise((resolve, reject) => {
            this.recordApi.getSignPlace(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取打卡信息
     * @param userId 
     * @returns 
     */
    public getUserInfo(userId: string): Promise<any> {
        const params = {
            userId: userId,
        };
        return new Promise((resolve, reject) => {
            this.recordApi.getUserInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取考勤记录
     * @param userId 
     * @returns 
     */
    public getAttList(userId: string): Promise<any> {
        const params = {
            userId: userId,
        };
        return new Promise((resolve, reject) => {
            this.recordApi.getAttList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public saveAttendance(userId: string, signType: number, lon: number, lat: number, address: string): Promise<any> {
        const params = {
            userId,
            signType,
            lon: lon,
            lat: lat,
            address: address
        }
        return new Promise((resolve, reject) => {
            this.recordApi.saveAttendance(params).then((res: any) => {
                console.log(res);
                resolve(res);
            }, (e: any) => {
                reject(e);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}
