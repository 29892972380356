
import { defineComponent, onMounted, reactive, ref } from "vue";
import onDutySignService from "@/services/attendance/onDutySign/onDutySignService";
import SigninService from "@/services/attendance/signin/SigninService";
import SystemUtils from "@/utils/SystemUtils";
import Location from "@/components/Location.vue";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "OutSignInList",
  components: {
    Location,
    Header,
  },
  setup() {
    const query = useRoute().query;
    const signInList = reactive({
      data: new Array(),
      total: 0,
    });

    const user = SystemUtils.loginUser;

    const signinService = new SigninService();

    const userPhoto = ref("");

    const loadUserInfo = () => {
      signinService
        .getUserInfoById(user.id, "")
        .then(
          (r: any) => {
            if (r) {
              userPhoto.value = r.photoHttp;
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    let page = 1;
    let size = 10;

    let startDate = "";
    let endDate = "";

    const isScroll = ref(false);
    const outSignMap = ref();

    const service = new onDutySignService();
    const loadList = () => {
      let userId = user.id;
      service
        .onDutySignList(userId + "", startDate + "", endDate + "", page, size)
        .then(
          (result: any) => {
            if (result) {
              // signInList.total = result.data.total;
              if (result.data.rows.length > 0) {
                let rows = result.data.rows;
                let markers = new Array();
                rows.forEach((dayItem: any) => {
                  dayItem.rows.forEach((item: any, i: number) => {
                    let atts = new Array();
                    item.attachments.forEach((url: string) => {
                      atts.push({
                        url: url,
                      });
                    });
                    item.attachments = atts;
                    if (i == 0) {
                      markers.push({ lon: item.longitude, lat: item.latitude });
                    }
                  });
                });
                if (page == 1) {
                  outSignMap.value.locationBaiduMarkers(markers, true);
                  signInList.data = rows;
                } else {
                  outSignMap.value.locationBaiduMarkers(markers, false);
                  signInList.data = signInList.data.concat(rows);
                }
                signInList.total = 0;
                for (let index = 0; index < signInList.data.length; index++) {
                  const element = signInList.data[index];

                  signInList.total = signInList.total + element.rows.length;
                }
              } else {
                if (page == 1) {
                  signInList.data.splice(0, signInList.data.length);
                  signInList.total = 0;
                }

                isScroll.value = true;
              }
            } else {
              // outSignMap.value.locationBaiMap();
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const router = useRouter();

    const goInto = (id: string, longitude: number, latitude: number) => {
      // router.push({
      //   name: "OutSignInDetail",
      //   params: {
      //     id: id,
      //     longitude: longitude,
      //     latitude: latitude,
      //   },
      // });
      outSignMap.value.centerAndZoom(longitude, latitude);
    };

    const bindChangeStartDate = (ev: any) => {
      startDate = moment(ev.detail.value).format("YYYY-MM-DD");
      initDate();
    };

    const bindChangeEndDate = (ev: any) => {
      endDate = moment(ev.detail.value).format("YYYY-MM-DD");
      initDate();
    };

    const initDate = () => {
      page = 1;
      loadList();
    };

    const loadData = (ev: any) => {
      setTimeout(() => {
        page += 1;
        loadList();
        ev.target.complete();
        if (signInList.data.length == 1000) {
          ev.target.disabled = true;
        }
      }, 500);
    };

    const fillZero = (num: number) => {
      if (num <= 9) {
        return "0" + num;
      }
      return num;
    };

    const startDate_def = ref("");
    const endDate_def = ref("");

    onMounted(() => {
      const currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth() + 1;
      startDate = startDate_def.value =
        currentYear + "-" + fillZero(currentMonth) + "-01";
      endDate = endDate_def.value =
        currentYear +
        "-" +
        fillZero(currentMonth) +
        "-" +
        fillZero(currentDate.getDate());
      loadUserInfo();
      initDate();
    });

    let location = "";
    let longitude = 0;
    let latitude = 0;

    const generateLocation = (r: any) => {
      if (r) {
        longitude = r.longitude;
        latitude = r.latitude;
        location = r.location;
      }
    };

    return {
      signInList,
      goInto,
      initDate,
      bindChangeStartDate,
      bindChangeEndDate,
      generateLocation,
      userPhoto,
      loadData,
      startDate_def,
      endDate_def,
      isScroll,
      outSignMap,
      query,
    };
  },
});
